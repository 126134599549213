/*
 * Globals
 */


/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  margin-right: 4px;
}


/*
 * Base structure
 */

body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
  height: 100vh;
}

.d-flex {
  height: 100%;
}

/*.cover-container {
  max-width: 42em;
  max-height: fit-content;
} */

.cover-container {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.custom-text {
  width:62%;
  overflow-y: scroll;
}

/*
 * Header
 */

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

.custom-code-block {
  font-size: 0.7rem;
}

.custom-text-left {
  text-align: left;
}